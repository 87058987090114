import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from '../components/layout';
import SEO from '../components/seo';
import QuoteCarousel from '../components/quote-carousel';
import PatCircles from '../images/pattern-circles.svg';
import IconDocs from '../images/icon-docs.svg';
import IconLibs from '../images/icon-libraries.svg';
import IconApi from '../images/icon-api.svg';
import IconWave from '../images/wave.svg';
import IconCommunity from '../images/icon-community.png';



const partnerUrl = 'https://www.pagerduty.com/become-a-partner/'
const IndexPage = (props) => {
  // Gatsby renders the index page before checking the location to see if
  // routing is necessary. This means the index page flashes before the
  // requested page when you visit a route directly. This hack renders a
  // blank index page if the browser location is not pointing to the index.
  const isIndexPage = typeof window !== "undefined" && window.location.pathname === '/';
  return isIndexPage && (
    <Layout>
      <SEO title="PagerDuty Developer Platform and Documentation"
           description="Learn how to use PagerDuty APIs to manage incidents,
           account settings, and more. Streamline how you create, publish, and
           showcase your integrations with the PagerDuty Developer Platform."/>
      <section className="topsection">
        <div className="py-3gut md:h-6lane sm:flex sm:items-center">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-20 mx-auto md:flex justify-between">
              <div className="intro-cards md:max-w-18gut sm:max-w-36gut bg-white rounded-lg overflow-hidden shadow-lg p-48">
                  <span className="inline-flex items-center rounded-full">
                    <img className="w-auto" src={IconApi}/>
                    <div className="font-medium text-md px-8">API Libraries</div>
                  </span>
                  <div className="px-6 my-gut">
                    <p className="text-gray-700 text-base content-center">
                    Easily build and integrate in the languages you already work in. Just to name a few we offer libraries with JavaScript, Python, and Go
                    </p>
                  </div>      
                  <div className="md:mt-2gut">
                    <a href="/docs/ZG9jOjExMDI5NTg2-api-client-libraries">
                      <button className="bg-blue-dark text-white font-bold py-8 px-8 rounded">Get Started</button>
                    </a>
                  </div>
              </div>
              <div className="intro-cards md:max-w-18gut sm:max-w-36gut bg-white rounded-lg overflow-hidden shadow-lg p-48">
                  <span className="inline-flex items-center rounded-full">
                    <img className="w-auto" src={IconDocs}/>
                    <div className="font-medium text-md px-8">Documentation</div>
                  </span>
                  <div className="px-6 my-gut">
                    <p className="text-gray-700 text-base content-center">
                    Explore PagerDuty APIs and functionality to start building! Here you’ll find resources, and references to build with PagerDuty. 
                    </p>
                  </div>
                  <div className="md:mt-2gut">
                    <a href="/docs/ZG9jOjQ2NDA2-introduction">
                      <button className="bg-blue-dark text-white font-bold py-8 px-8 rounded">Read More</button>
                    </a>
                  </div>
              </div>
              <div className="intro-cards md:max-w-18gut sm:max-w-36gut bg-white rounded-lg overflow-hidden shadow-lg p-48">
                  <span className="inline-flex items-center rounded-full">
                    <img className="w-auto" src={IconCommunity}/>
                    <div className="font-medium text-md px-8">Community</div>
                  </span>
                  <div className="px-6 my-gut">
                    <p className="text-gray-700 text-base content-center">
                    Learn, network, and share with the PagerDuty Community! Share successes or get help from other community members.
                    </p>
                  </div>
                  <div className="md:mt-2gut">
                    <button className="bg-blue-dark text-white font-bold py-8 px-8 rounded">
                      <a href="https://community.pagerduty.com/">
                        Join Now
                      </a>
                    </button>
                  </div>
              </div>
          </div>
        </div>
        <div className="text-center text-md md:text-lg text-gray-dark">What do you want to do with the PagerDuty API?</div>
        <div className="py-3gut md:py-0 md:h-2lane sm:flex sm:items-center">
          <div className="container mx-auto md:flex">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-12 mx-auto">
              <div className="sub-cards max-w-sm hover:bg-gray-xlight bg-white rounded-xl overflow-hidden shadow-lg border border-gray-xlight">
                <a href="/integration-developer"><p className="text-gray-700 font-light text-gray-dark content-center text-center p-32">Create an Integration</p></a>  
              </div>
              <div className="max-w-sm hover:bg-gray-xlight bg-white rounded-xl overflow-hidden shadow-lg border border-gray-xlight">
                <a href="/customize-your-experience"><p className="text-gray-700 font-light text-gray-dark content-center text-center p-32">Customize Your Experience</p></a>
              </div>
            </div>
          </div>
        </div>
       
        <footer className="text-white bg-blue-dark md:flex md:h-6lane lg:h-8lane">
          <div className="flex-container-2/3 py-3gut md:py-0 md:h-full md:flex md:flex-col md:justify-center">
            <div className="container-2/3 mx-auto md:ml-auto md:mr-0">
              <h2 className="px-gut font-extralight leading-none text-2xl">
                Partner with PagerDuty
              </h2>
              <div className="flex flex-wrap">
                <article className="px-gut mt-3gut w-4lane">
                  <h3 className="text-yellow text-lg mb-8">Increase your reach</h3>
                  <p>Connect users with your solution</p>
                </article>

                <article className="px-gut mt-3gut w-4lane">
                  <h3 className="text-yellow text-lg mb-8">Accelerate adoption</h3>
                  <p>Drive traffic to your product</p>
                </article>

                <article className="px-gut mt-3gut w-4lane">
                  <h3 className="text-yellow text-lg mb-8">Streamline development</h3>
                  <p>Speed development by partnering with PagerDuty</p>
                </article>

                <article className="px-gut mt-3gut w-4lane">
                  <h3 className="text-yellow text-lg mb-8">Free to join</h3>
                  <p>No fees to develop integrations</p>
                </article>
              </div>

              <div className="mt-3gut px-gut flex">
                <a href={partnerUrl}
                   rel="noopener"
                   className="hover:text-yellow flex items-center h-3gut mr-auto">
                  <span>→</span> <span className="px-gut">Become a PagerDuty partner</span>
                </a>
              </div>
            </div>
          </div>
          <div className="hidden md:block flex-container-1/3">
            <GatsbyImage className="h-full w-full object-cover object-top"
                         alt=""
                         image={props.data.dev.childImageSharp.gatsbyImageData} />
          </div>
        </footer>
      </section>
      <section>
        <header className="py-3gut sm:py-0 sm:h-4lane md:h-3lane sm:flex sm:items-center">
          <div className="container mx-auto sm:flex sm:items-center">
            <h2 className="px-gut sm:w-1/2 font-extralight leading-none text-2xl">
              Building <br className="hidden md:block lg:hidden"/>
              with PagerDuty
            </h2>
            <p className="px-gut mt-3gut sm:my-0 sm:w-1/2">
              PagerDuty’s easy to use, self-service developer account makes
              getting started with integrations simple. Over 12,000 happy
              customers around the world count on PagerDuty for DevOps, IT Ops,
              Security, Customer Service, Business Ops, Industrial Ops and IOT,
              and many other use cases.
            </p>
          </div>
        </header>
        <section className="py-3gut sm:h-5lane sm:flex sm:justify-center">
          <div className="container mx-auto flex flex-col justify-center">
            <QuoteCarousel/>
          </div>
        </section>

        <section className="py-3gut sm:pt-0 sm:pb-8gut">
          <h3 className="container mx-auto h-6gut px-gut flex items-center
                     font-extralight leading-none text-xl">
            Build what’s next
          </h3>
          <div className="container mx-auto md:flex">
            <article className="px-gut mb-4gut md:mb-0 md:w-1/3 md:flex md:flex-col">
              <h4 className="mb-2gut font-extralight text-lg">
                DevOps
                <div className="text-md">
                  Turn signals into action
                </div>
              </h4>
              <p className="mb-gut md:mb-2gut">
                The digital world is always on. Application, infrastructure, and
                other monitoring tools provide teams with the signals they need
                to keep it that way. Connect your monitoring tool to PagerDuty
                to turn alerts into actionable real-time operations.
              </p>
              {/*<div className="mt-auto">
                <a href="#"
                   className="text-green hover:text-green-dark
                         flex items-center h-3gut">
                  <span>→</span> <span className="pl-gut pr-2gut">Learn more</span>
                </a>
              </div>*/}
            </article>
            <article className="px-gut mb-4gut md:mb-0 md:w-1/3 md:flex md:flex-col">
              <h4 className="mb-2gut font-extralight text-lg">
                Security
                <div className="text-md">
                  Power modern SecOps
                </div>
              </h4>
              <p className="mb-gut md:mb-2gut">
                Connecting your security tool to PagerDuty means development and
                operations engineers can bring security professionals onto a
                common platform to reduce risk, enabling them to resolve
                security alerts faster.
              </p>
              {/*<div className="mt-auto">
                <a href="#"
                   className="text-green hover:text-green-dark
                         flex items-center h-3gut">
                  <span>→</span> <span className="pl-gut pr-2gut">Learn more</span>
                </a>
              </div>*/}
            </article>
            <article className="px-gut mb-4gut md:mb-0 md:w-1/3 md:flex md:flex-col">
              <h4 className="mb-2gut font-extralight text-lg">
                Business Ops
                <div className="text-md">
                  Modern business operations
                </div>
              </h4>
              <p className="mb-gut md:mb-2gut">
                Every business depends on digital operations in one way or
                another. Connect your business operations tool to the PagerDuty
                Developer Platform for Real-time Operations, enabling the right
                responders to act quickly with context.
              </p>
              {/*<div className="mt-auto">
                <a href="#"
                   className="text-green hover:text-green-dark
                         flex items-center h-3gut">
                  <span>→</span> <span className="pl-gut pr-2gut">Learn more</span>
                </a>
              </div>*/}
            </article>
          </div>
        </section>
      </section>
      <section className="text-white bg-gray-dark py-3gut sm:h-5lane sm:flex sm:flex-4lane sm:justify-center">
        <div className="container mx-auto flex flex-col justify-center">
          <h2 className="px-gut font-extralight leading-none text-2xl sm:text-3xl">
            Get started building on the <br className="hidden sm:block"/>
            <em className="not-italic text-yellow">PagerDuty Developer Platform</em>
          </h2>
          <ul className="mt-4gut sm:mt-lane">
            <li className="w-3lane px-gut">
              <a href="/sign-up/"
                 className="uppercase text-gray-dark bg-white hover:bg-yellow
                            flex justify-center items-center h-3gut">
                <span>Sign up</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <footer className="container mx-auto p-gut h-gut text-sm text-gray-dark text-right mb-gut">
        © {new Date().getFullYear()}, PagerDuty
      </footer>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    dev: file(relativePath: { eq: "dev.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
