import React from 'react';
import Slider from "react-slick";

import "./quote-carousel.scss"
import SentryLogo from '../images/logo-sentry.svg';
import ContrastSecurityLogo from '../images/logo-contrast-security.png';
import ApptioCloudabilityLogo from '../images/logo-apptio-cloudability.png';


export default class QuoteCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slideIndex: 0
        };
    }

    render() {
        const activeTabClass = 'active'

        const settings = {
            dots: true,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000,
            arrows: false,
            beforeChange: (current, next) => this.setState({ slideIndex: next })
        };

        return (
            <div className="quote-carousel container">
                <div className="quote-carousel-tabs flex flex-row bg-blue-dark text-white text-md">
                    <span className="flex items-center" onClick={() => this.slider.slickGoTo(0)}>
                        <div className={this.state.slideIndex === 0 ? activeTabClass : ''}>
                            DevOps
                        </div>
                    </span>
                    <span className="flex items-center" onClick={() => this.slider.slickGoTo(1)}>
                        <div className={this.state.slideIndex === 1 ? activeTabClass : ''}>
                            Security
                        </div>
                    </span>
                    <span className="flex items-center" onClick={() => this.slider.slickGoTo(2)}>
                        <div className={this.state.slideIndex === 2 ? activeTabClass : ''}>
                            BizOps
                        </div>
                    </span>
                </div>
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                    <div className="container flex flex-col px-1 md:flex-row carousel-item md:h-3lane items-center">
                        <div className="w-2/3 md:w-1/3">
                            <img className="logo mx-auto" src={SentryLogo} />
                        </div>
                        <div className="md:w-2/3 quote-container">
                            <div className="text-md quote">&quot;Combining error monitoring with an automated{' '}
                            escalation process ensures the right people fix the right problem.{' '}
                            Leveraging the PagerDuty Developer Platform allowed us to{' '}
                            quickly deliver this value and keep focus on our core product.&quot;</div>
                            <div className="attribution"><strong>Dave Hayes</strong>, Head of Product, Sentry.io</div>
                        </div>
                    </div>
                    <div className="container flex flex-col px-1 md:flex-row carousel-item md:h-3lane items-center">
                        <div className="w-2/3 md:w-1/3">
                            <img className="logo mx-auto" src={ContrastSecurityLogo} />
                        </div>
                        <div className="md:w-2/3 quote-container">
                            <div className="text-md quote">&quot;One of our goals is to help teams respond to application
                            vulnerabilities and attacks in real time. Leveraging PagerDuty’s response management
                            platform was a logical step to deliver increased security posture for our customers.&quot;</div>
                            <div className="attribution"><strong>Gary Moore</strong>, Director of Engineering, Contrast Security</div>
                        </div>
                    </div>
                    <div className="container flex flex-col px-1 md:flex-row carousel-item md:h-3lane items-center">
                        <div className="w-2/3 md:w-1/3">
                            <img className="logo mx-auto" src={ApptioCloudabilityLogo} />
                        </div>
                        <div className="md:w-2/3 quote-container">
                            <div className="text-md quote">&quot;The PagerDuty Developer Platform greatly{' '}
                            reduced our time to deliver additional features.{' '}
                            Cloudability customers can now quickly discover and respond{' '}
                            to cloud spending anomalies to avoid racking up expensive bills.&quot;</div>
                            <div className="attribution"><strong>Matthew Finlayson</strong>, VP of Engineering, Apptio Cloudability</div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}
